import React, { useState } from 'react';
import { Card, Button, Divider } from 'antd-mobile';
import { SelectRecord } from './Ywgl';
import './SelectRecordList.css';

interface SelectRecordListProps {
  selectRecord: SelectRecord[];
  onSelect: (record: SelectRecord) => void;
  xzqhLabel: (key: string) => string;
  dyTypeLabel: (key: string) => string;
}

const SelectRecordList: React.FC<SelectRecordListProps> = ({
  selectRecord,
  onSelect,
  xzqhLabel,
  dyTypeLabel,
}) => {
  const [loading, setLoading] = useState<number | null>(null);

  const handleSelect = async (record: SelectRecord, index: number) => {
    setLoading(index);
    await onSelect(record);
    setLoading(null);
  };

  return (
    <div className="select-record-list">
      {selectRecord.map((record, index) => (
        <Card key={index} title={`坐落: ${record.zl}`} className="card-item">
          <div className="card-content">
            <span>不动产权证号: {record.qzh}</span>
            <Divider direction="vertical" />
            <span>权利人: {record.qlr}</span>
            <Divider direction="vertical" />
            <span>面积: {record.mj} 平米</span>
            <Divider direction="vertical" />
            <span>单元类型: {dyTypeLabel(record.dyType)}</span>
            <Divider direction="vertical" />
            <span>办理区域: {xzqhLabel(record.qhdm)}</span>
          </div>
          <div style={{ textAlign: 'right' }}>
            <Button
              onClick={() => handleSelect(record, index)}
              color="primary"
              fill="solid"
              size="small"
              loading={loading === index}
            >
              选择
            </Button>
          </div>
        </Card>
      ))}
    </div>
  );
};

export default SelectRecordList;
